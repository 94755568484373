<script>
  import Likes from "./likes/Likes.vue";

  export default {
    components: {
      UsersListVisor: () => import("../widgets/Modals/UsersListVisor.vue"),
      Likes,
    },
    props: ["participant", "award", "position", "idxCard"],
    data() {
      return {
        showModalViewers: false,
        showModalLikes: false,
      };
    },
    computed: {
      imgParticipant() {
        return this.participant?.internalPicture;
      },
      nameParticipant() {
        const { artisticName, user } = this.participant.user;
        return artisticName || user;
      },
      cityParticipant() {
        return this.participant.user.city;
      },
      awardImg() {
        return this.award && this.award?.logo;
      },
      participantViewers() {
        return this.participant?.viewers?.length ? this.participant.viewers.map(({ user, views }) => ({ ...user, views })) : [];
      },
      participantLikes() {
        return this.participant?.likes.length ? this.participant.likes.map((it) => it.userId) : [];
      },
      userIsAdmin() {
        return this.$userData.role === "superadmin";
      },
    },
    methods: {
      showImgModal() {
        this.$emit("showModalDetails", this.participant, this.idxCard, false);
      },
      showModal() {
        this.showModalViewers = !this.showModalViewers;
      },
      showLikesMd(event) {
        event.stopPropagation();
        this.showModalLikes = !this.showModalLikes;
      },
      scaleImage(scaleUp, id, action) {
        const competitionBuble = this.$refs[`bubbleitem_${id}`];
        if (action) {
          competitionBuble.style.transform = "scale(1.2)";
          competitionBuble.style.backgroundColor = "#000000bf";
        } else {
          competitionBuble.style.transform = "scale(1)";
          competitionBuble.style.backgroundColor = "#0000009c";
        }
      },
    },
  };
</script>

<template>
  <article class="competition__card" @mouseover="($event) => scaleImage($event, participant._id, true)" @mouseleave="($event) => scaleImage($event, participant._id, false)">
    <div class="competition__awardContainerCard">
      <div class="competition__awardNameContainerCard">
        <img v-if="awardImg" class="competition__awardCardImg" :src="awardImg" :alt="awardImg" />
        <div class="competition__nameCard">
          <span class="competition__awardNameCard">{{ nameParticipant }}</span>
          <div class="competition__contentDetails">
            <span class="competition__awardCityCard">{{ cityParticipant }}</span>
            <div class="competition__views" @click="showModal" v-if="participantViewers.length && userIsAdmin">
              <iconic class="competition__eye" name="eye_contets" />
              <p>{{ participantViewers.length }}</p>
            </div>
          </div>
        </div>
      </div>
      <span class="competition__bubbleCard" :ref="'bubbleitem_' + participant._id">{{ position }}</span>
      <likes
        class="competition__likeCard"
        :likes="participant.likes"
        :totalLikes="participant.totalLikes"
        :postId="participant._id"
        nameEvent="likeParticipants"
        endpoint="/participants/likes"
        :modalaction="showLikesMd"
      />
    </div>
    <div class="competition__image" @click.prevent="showImgModal" :style="{ 'background-image': 'url(' + imgParticipant + ')' }"></div>
    <UsersListVisor :users="participantLikes" :detail="'Usuarios que han dado like a la participante:'" :closeModal="showLikesMd" v-if="showModalLikes" />
    <UsersListVisor :users="participantViewers" :detail="'Usuarios que han visto la participante:'" :closeModal="showModal" v-if="showModalViewers" />
  </article>
</template>

<style lang="scss">
  .competition {
    &__card {
      position: relative;
      width: 100%;
      height: 123px;
      border-radius: 10px;
      box-shadow: 0 1px 4px rgba(99, 99, 99, 0.6);
      cursor: pointer;
      overflow: hidden;
    }
    &__contentDetails {
      @include Flex(row);
      gap: 5px;
    }
    &__views {
      @include Flex(row);
      color: $white;
      gap: 10px;
      padding: 0 6px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
    &__eye {
      font-size: 14px;
    }
    &__image {
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(1.1);
      transition: transform 0.5s ease-in-out;
      overflow: hidden;
      bottom: 0;
      left: 0;
      &::after {
        content: "";
        position: absolute;
        height: 28%;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 0 0 10px 10px;
        background-image: linear-gradient(0deg, #020202c2 0%, rgba(15, 15, 15, 26%) 0%, rgba(19, 19, 19, 0));
      }
      &:hover {
        transform: scale(1);
      }
    }
    &__awardContainerCard {
      position: relative;
      height: 100%;
      width: 100%;
    }
    &__awardNameContainerCard {
      position: absolute;
      bottom: 0.8rem;
      left: 0.3rem;
      z-index: 10;
      @include Flex();
      gap: 10px;
    }
    &__awardCardImg {
      @include Flex();
      width: 2.1rem;
      height: 2.1rem;
      color: #fff;
    }
    &__nameCard {
      @include Flex(column, flex-start, flex-start);
    }
    &__awardNameCard {
      font-family: $sec_font;
      font-size: 16px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 17ch;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
    }
    &__awardCityCard {
      font-family: $primary_font;
      font-size: 12px;
      line-height: 10px;
      color: #fff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 64%);
    }

    &__bubbleCard {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      z-index: 10;
      @include Flex();
      align-self: flex-start;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #0000009c;
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      transition: all 0.4s ease;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.82);
      &:hover {
        background-color: $primary;
        transform: scale(1.2);
      }
    }
    &__likeCard {
      position: absolute;
      bottom: 1px;
      z-index: 10;
      transform: scale(0.8);
    }

    &__likeCard > * {
      color: #0000009c;
    }

    @media (min-width: 500px) {
      &__card {
        height: 220px;
      }
      &__awardNameCard {
        font-size: 20px;
      }
    }
    @media (min-width: 700px) {
      &__likeCard {
        bottom: 0.3rem;
        right: 1px;
      }
      &__awardNameContainerCard {
        bottom: 0.8rem;
        left: 0.8rem;
      }
      &__contentDetails {
        gap: 10px;
      }
      &__awardCityCard {
        font-size: 14px;
      }
    }
  }
</style>
