<script>
  import Carrousel from "@/components/Competitions/CompetitionCarrousel.vue";
  import Card from "@/components/Competitions/Card.vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import ModalCompetition from "./ModalCompetition.vue";
  import { socket } from "@/socket";

  export default {
    components: {
      Card,
      ModalCompetition,
      Carrousel,
      UsersListVisor: () => import("../widgets/Modals/UsersListVisor.vue"),
    },
    data() {
      return {
        headerCompetitions: [],
        awards: [],
        selectedParticipantId: 0,
        showModal: false,
        page: 0,
        pageViewers: 0,
        hasMore: true,
        isLoading: true,
        isLoadingViewers: true,
        bestAwards: [],
        savedIdxUserSelected: null,
        resetCarrousel: 0,
        totalViewersContest: 0,
        userViewers: [],
        hasMoreViewers: false,
        showModalViewers: false,
        positionPodium: 1,
        showComments: false,
        showCompetition: false,
        lastBodyStyleOverflowY: "",
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetitions", "getCompetition", "getRanking"]),
      selectedParticipant() {
        return this.getRanking[this.selectedParticipantId];
      },
      userIsAdmin() {
        return this.$userData.role === "superadmin";
      },
      commentsData() {
        return this.$store.state.comments.comment;
      },
      best() {
        return this.getRanking.slice(0, this.getCompetition.totalPodium);
      },
      participants() {
        return this.getRanking.slice(this.getCompetition.totalPodium);
      },
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionsDb", "getCompetitionById", "getRankingDb"]),
      ...mapActions(["setItemViewed", "getItemViewersTotal", "getItemViewers"]),
      ...mapActions("comments", ["getCommentsByModel"]),
      ...mapMutations("competitions", ["setRanking", "setNewRanking"]),
      async getComment(part) {
        await this.$store.dispatch("comments/getCommentsByModel", part);
      },
      async changeSection(competitionId) {
        await this.getCompetitionById({ _id: competitionId });

        this.page = 0;
        await this.createRanking();
        this.setNewView(competitionId);
        this.getTotalViewersContest(competitionId);
      },
      createCompetition() {
        this.$router.push("/competition/createCompetition");
      },
      async editCompetition() {
        return this.$router.push(`/competition/editCompetition/${this.getCompetition._id}`).catch(() => {});
      },
      async listCompetition() {
        return this.$router.push(`/competition/list`).catch(() => {});
      },
      activeSection(competitionId) {
        return this.getCompetition._id === competitionId;
      },
      validateInteration() {
        this.showComments = this.getCompetition?.policiesInteration?.users.includes(this.$user._id);
      },
      validateCompetitionView() {
        this.showCompetition = this.getCompetition?.policiesView?.users.includes(this.$user._id);
      },
      disableScrolling() {
        if (!document) return;
        this.lastBodyStyleOverflowY = document.body.style.overflowY;
        document.body.style.overflowY = "hidden";
      },
      enableScrolling() {
        if (!document) return;
        document.body.style.overflowY = "initial";
      },
      showModalDetails(participant, idx, ran) {
        this.showModal = !this.showModal;
        this.disableScrolling();

        if (!this.showModal) {
          this.enableScrolling();
          return;
        }
        this.savedIdxUserSelected = idx;

        this.getParticipantAward(this.savedIdxUserSelected);

        const idxModel = ran ? idx : idx + 3;

        this.selectedParticipantId = idxModel;
        this.getComment(this.selectedParticipant._id);
        this.objCurrentAwads = ran ? this.getAwardByModalTop() : this.getAwardByModal();

        this.setNewView(participant._id);
      },
      setNewView(itemId) {
        if (this.getCompetition?.status === "active") {
          this.setItemViewed(itemId);
        }
      },
      async getMore() {
        if (!this.hasMore || this.isLoading) {
          return;
        }
        this.isLoading = true;

        this.page++;
        const { hasMore, ranking = [] } = await this.getRankingDb({ contestId: this.getCompetition._id, query: `page=${this.page}` });

        this.hasMore = hasMore;
        this.setNewRanking(ranking);
        this.isLoading = false;
      },
      getParticipantAward(position) {
        if (this?.statusRange) {
          position++;
          return this.getCompetition.awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
        } else {
          if (this.getCompetition.awards && position >= 0 && position < this.getCompetition.awards.length) {
            return this.getCompetition.awards[position];
          }
        }
      },
      getAwardByModalTop() {
        if (this?.statusRange) {
          let position = this.savedIdxUserSelected + 1;
          return this.getCompetition.awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
        } else {
          return this.getCompetition.awards[this.savedIdxUserSelected] || null;
        }
      },
      getAwardByModal() {
        if (this?.statusRange) {
          let position = this.savedIdxUserSelected + 4;
          return this.getCompetition.awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
        } else {
          return this.getCompetition.awards[this.savedIdxUserSelected + 3] || null;
        }
      },
      async createRanking() {
        this.isLoading = true;
        const { _id, totalPodium } = this.getCompetition;
        this.positionPodium += totalPodium;
        const { ranking, hasMore } = await this.getRankingDb({ contestId: _id, query: `page=${this.page}` });

        this.hasMore = hasMore;
        this.setRanking(ranking);
        this.resetCarrousel++;

        this.bestAwards = this.getCompetition.awards;
        this.statusRange = this.getCompetition.positionByRange;
        this.isLoading = false;
        this.validateInteration();
        this.validateCompetitionView();
      },
      async getTotalViewersContest(itemId) {
        this.totalViewersContest = await this.getItemViewersTotal(itemId);
      },
      async showUsersView() {
        this.pageViewers = 0;
        this.userViewers = await this.getViewers();
        this.showModalViewers = true;
      },
      async getMoreViewers() {
        if (!this.hasMoreViewers || this.isLoadingViewers) {
          return;
        }
        this.pageViewers++;
        this.userViewers.push(...(await this.getViewers()));
      },
      async getViewers() {
        if (!this.getCompetition?._id) {
          return;
        }
        this.isLoadingViewers = true;

        const { viewers, hasMore } = await this.getItemViewers({ item: this.getCompetition._id, page: this.pageViewers });

        this.hasMoreViewers = hasMore;
        this.isLoadingViewers = false;

        return viewers.map(({ user, dates }) => ({ ...user, views: dates.length })) || [];
      },
    },
    async mounted() {
      await this.getCompetitionsDb();
      this.headerCompetitions = this.getCompetitions.filter((obj) => obj?.status === "active");

      if (this.headerCompetitions.length) {
        if (this.getCompetition?._id) {
          await this.changeSection(this.getCompetition._id);
        }
        if (!this.showCompetition) {
          return this.$router.push(`/competition/list`).catch(() => {});
        }
        if (socket) {
          socket.on("createPointsContest", async (payload) => {
            if (!payload) return;
            this.page = 0;
            this.createRanking();
          });
        }
      } else {
        return this.$router.push(`/competition/list`).catch(() => {});
      }
    },
  };
</script>

<template>
  <section class="competitions">
    <div class="competitions__container" v-show="showCompetition">
      <div class="competitions__containerMenu">
        <p class="competitions__title">CONCURSOS</p>
        <div v-if="$user.role === 'superadmin'" class="competitions__icons">
          <div class="competitions__views" @click="showUsersView" v-if="totalViewersContest && userIsAdmin">
            <iconic name="eye_contets" class="competitions__eye" />
            <span>{{ totalViewersContest }}</span>
          </div>
          <div @click="() => listCompetition()">
            <iconic name="list" class="competitions__plus" />
          </div>
          <div @click="() => editCompetition()">
            <iconic name="edit_pencil" class="competitions__plus" />
          </div>
          <div @click="() => createCompetition()">
            <iconic name="plus" class="competitions__plus" />
          </div>
        </div>
        <div class="competitions__option">
          <p
            @click="changeSection(competition._id)"
            v-for="competition in headerCompetitions"
            :key="competition._id"
            :class="['competitions__section', { competitions__sectionActive: activeSection(competition._id) }]"
          >
            {{ competition.name }}
          </p>
        </div>
        <router-view></router-view>
      </div>
      <div v-if="best.length">
        <Carrousel :best="best" :awards="bestAwards" :subtitle="getCompetition.description" @showModalDetails="showModalDetails" :key="resetCarrousel" :modal="true" :statusRange="this.statusRange" />
      </div>
      <div class="competitions__cardContainer">
        <Card
          @showModalDetails="showModalDetails"
          :idxCard="idx"
          v-for="(participant, idx) in participants"
          :key="participant.user._id"
          :participant="participant"
          :award="getParticipantAward(idx + 3)"
          :position="idx + positionPodium"
        />
        <button class="click-on-bottom" @click="getMore">Ver más</button>
      </div>
      <ModalCompetition v-if="showModal" @close="showModalDetails()" :participant="selectedParticipant" :award="objCurrentAwads" :comments="commentsData" :showComments="showComments" />
      <UsersListVisor
        :users="userViewers"
        :isLoading="isLoadingViewers"
        :hasMore="hasMoreViewers"
        :detail="'Usuarios que han visto el concurso:'"
        :closeModal="() => (showModalViewers = false)"
        :getMore="getMoreViewers"
        v-if="showModalViewers"
      />
    </div>
    <router-view></router-view>
  </section>
</template>

<style lang="scss">
  .competitions {
    padding-top: 50px;
    @include Flex(row, center, flex-start);
    min-width: 100%;
    min-height: 100%;
    &__container {
      width: 100%;
      max-width: 1440px;
    }
    &__containerMenu {
      position: relative;
    }
    &__title {
      font-family: $sec_font;
      font-size: 32px;
      color: $primary;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    &__views {
      @include Flex(row);
      gap: 10px;
      padding: 5px 10px;
      font-weight: 600;
      color: $chicago;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
    &__eye {
      font-size: 20px;
    }
    &__icons {
      position: absolute;
      top: 1rem;
      right: 0;
      display: flex;
    }
    &__plus {
      padding: 0.5rem;
      margin-left: 10px;
      background: $primary;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
        background-color: rgba(255, 26, 75, 1);
      }
    }
    &__list {
      @include Flex(row, flex-start);
      gap: 1rem;
      min-width: 100%;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid rgba(216, 216, 216, 1);
    }
    &__listItem {
      color: $primary;
      border-bottom: 2px solid $primary;
      font-weight: bold;
      text-transform: capitalize;
    }
    &__option {
      @include Flex(row, flex-start);
      max-width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }
    &__section {
      padding: 10px;
      color: $primary-color;
      font-weight: 600;
      font-size: 14px;
      font-family: $primary_font;
      cursor: pointer;
    }
    &__sectionActive {
      border-bottom: 2px solid $primary-color;
    }
    &__cardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden;
      gap: 10px;
      padding: 0 0.2rem;
      min-width: 100%;
      margin: 1rem 0;
    }
    @media (min-width: 500px) {
      &__cardContainer {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (min-width: 1440px) {
      &__cardContainer {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
</style>
